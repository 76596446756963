<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="`${i} Name`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Name`">
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="`${i} Name`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <LocaleTabs
                :errors="formErrors"
                name="job_title"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`job_title.${k}`"
                    :name="`${i} Job Title`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Job Title`">
                      <b-form-input
                        v-model="form.job_title[k]"
                        :placeholder="`${i} Job Title`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Mobile"
                vid="mobile"
                rules="numeric|min:10|max:14"
              >
                <b-form-group
                  label="Mobile"
                >
                  <b-form-input
                    v-model="form.mobile"
                    type="number"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                vid="email"
                rules="email"
              >
                <b-form-group
                  label="Email"
                >
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="Order"
                vid="order"
                rules="numeric"
              >
                <b-form-group
                  label="Order"
                >
                  <b-form-input
                    v-model="form.order"
                    type="number"
                    placeholder="Order"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="job_description"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`job_description.${k}`"
                    :name="`${i} Job Description`"
                    rules="required"
                  >
                    <b-form-group :label="`${i} Job Description`">
                      <b-form-textarea
                        v-model="form.job_description[k]"
                        :placeholder="`${i} Job Description`"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12" class="mb-2">
              <legend
                tabindex="-1"
                class="bv-no-focus-ring col-form-label pt-0"
              >Avatar</legend>
              <ValidationProvider
                v-slot="{ errors }"
                vid="avatar"
                name="Avatar"
              >
                <MediaLibraryAttachment
                  ref="mediaComponent"
                  :initial-value="media"
                  :max-items="1"
                  name="avatar"
                  :headers="headers"
                  routePrefix="media-library"
                  :uploadDomain="baseURL"
                  @change="getFiles($event)"
                  :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 2048 }"
                />
                <small class="text-danger">{{ errors.avatar_uuid }}</small>
              </ValidationProvider>
            </b-col>

            <!--facebook-->
            <b-col cols="12">
              <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Social Links</legend>
              <b-row>
                <!--Facebook-->
                <b-col cols="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Facebook"
                    vid="facebook"
                  >
                    <b-form-group
                      label="Facebook"
                    >
                      <b-form-input
                        v-model="form.social_media_links.facebook"
                        type="url"
                        placeholder="Facebook"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!--twitter-->
                <b-col cols="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Twitter"
                    vid="twitter"
                  >
                    <b-form-group
                      label="Twitter"
                    >
                      <b-form-input
                        v-model="form.social_media_links.twitter"
                        type="url"
                        placeholder="Twitter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!--Linkedin-->
                <b-col cols="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Linkedin"
                    vid="linkedin"
                  >
                    <b-form-group
                      label="Linkedin"
                    >
                      <b-form-input
                        v-model="form.social_media_links.linkedin"
                        type="url"
                        placeholder="Linkedin"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                Status
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import _ from 'lodash'
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from '../../components/media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  components: {
    MediaLibraryAttachment,
  },
  mixins: [formMixin],
  data() {
    return {
      imageAfterUpload: [],
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      media: null,
      avatar_uuid: null,
      validationErrors: {
        avatar_uuid: 'The Avatar field is required',
      },
      form: {
        social_media_links: {
          facebook: null,
          twitter: null,
          linkedin: null,
        },
        name: {
          en: null,
          ar: null,
        },
        job_title: {
          en: null,
          ar: null,
        },
        job_description: {
          en: null,
          ar: null,
        },
        order: null,
        mobile: null,
        email: null,
        avatar_uuid: null,
        status: 'active',
      },
      form_data: null,
    }
  },
  watch: {
    avatar(val) {
      if (val) {
        this.form.avatar_uuid = val
      } else {
        this.form.avatar_uuid = 'delete'
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      this.form.avatar_uuid = Object.keys(this.imageAfterUpload[0])[0]
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name_value,
            job_title: data.job_title_value,
            job_description: _.isArray(data.job_description_value) ? {
              en: null,
              ar: null,
            } : data.job_description_value,
            order: data.order,
            mobile: data.mobile,
            email: data.email,
            status: data.status,
            avatar_uuid: data.media?.uuid || null,
            social_media_links: data.social_media_links,
          }
          this.media = data.media
          this.avatar_uuid = data.media?.uuid || null
          let arr = []
          const obj = {
            attributes: this.media,
            clientValidationErrors: [],
            client_preview: data.media?.original_url,
            upload: {},
          }
          arr.push(obj)
          this.$refs.mediaComponent.mediaLibrary.changeState(state => {
            state.media = arr
          })
        })
    },
    loadFormData() {
      const $form = _.cloneDeep(this.form)
      this.form_data = $form
      if (this.isEdit) {
        if (!($form.avatar instanceof File) && $form.avatar !== 'delete') {
          delete $form.avatar
        }
        // eslint-disable-next-line no-unused-vars
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>
