var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "name"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "name.".concat(k),
                    "name": "".concat(i, " Name"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var errors = _ref2.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Name")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Name")
                        },
                        model: {
                          value: _vm.form.name[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.name, k, $$v);
                          },
                          expression: "form.name[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "job_title"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "job_title.".concat(k),
                    "name": "".concat(i, " Job Title"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref3) {
                      var errors = _ref3.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Job Title")
                        }
                      }, [_c('b-form-input', {
                        attrs: {
                          "placeholder": "".concat(i, " Job Title")
                        },
                        model: {
                          value: _vm.form.job_title[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.job_title, k, $$v);
                          },
                          expression: "form.job_title[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Mobile",
            "vid": "mobile",
            "rules": "numeric|min:10|max:14"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Mobile"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "placeholder": "Mobile"
                },
                model: {
                  value: _vm.form.mobile,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "mobile", $$v);
                  },
                  expression: "form.mobile"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "vid": "email",
            "rules": "email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Email"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "email",
                  "placeholder": "Email",
                  "autocomplete": "off"
                },
                model: {
                  value: _vm.form.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "email", $$v);
                  },
                  expression: "form.email"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Order",
            "vid": "order",
            "rules": "numeric"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Order"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "placeholder": "Order"
                },
                model: {
                  value: _vm.form.order,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "order", $$v);
                  },
                  expression: "form.order"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('LocaleTabs', {
          attrs: {
            "errors": _vm.formErrors,
            "name": "job_description"
          },
          scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
            return {
              key: "lang".concat(idx),
              fn: function fn(slotScope) {
                return [_c('ValidationProvider', {
                  key: idx,
                  class: slotScope.selectedTab !== idx ? 'hidden' : '',
                  attrs: {
                    "vid": "job_description.".concat(k),
                    "name": "".concat(i, " Job Description"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref7) {
                      var errors = _ref7.errors;
                      return [_c('b-form-group', {
                        attrs: {
                          "label": "".concat(i, " Job Description")
                        }
                      }, [_c('b-form-textarea', {
                        attrs: {
                          "placeholder": "".concat(i, " Job Description")
                        },
                        model: {
                          value: _vm.form.job_description[k],
                          callback: function callback($$v) {
                            _vm.$set(_vm.form.job_description, k, $$v);
                          },
                          expression: "form.job_description[k]"
                        }
                      }), _c('small', {
                        staticClass: "text-danger"
                      }, [_vm._v(_vm._s(errors[0]))])], 1)];
                    }
                  }], null, true)
                })];
              }
            };
          })], null, true)
        })], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "cols": "12"
          }
        }, [_c('legend', {
          staticClass: "bv-no-focus-ring col-form-label pt-0",
          attrs: {
            "tabindex": "-1"
          }
        }, [_vm._v("Avatar")]), _c('ValidationProvider', {
          attrs: {
            "vid": "avatar",
            "name": "Avatar"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('MediaLibraryAttachment', {
                ref: "mediaComponent",
                attrs: {
                  "initial-value": _vm.media,
                  "max-items": 1,
                  "name": "avatar",
                  "headers": _vm.headers,
                  "routePrefix": "media-library",
                  "uploadDomain": _vm.baseURL,
                  "validation-rules": {
                    accept: ['image/webp'],
                    maxSizeInKB: 2048
                  }
                },
                on: {
                  "change": function change($event) {
                    return _vm.getFiles($event);
                  }
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors.avatar_uuid))])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('legend', {
          staticClass: "bv-no-focus-ring col-form-label pt-0",
          attrs: {
            "tabindex": "-1"
          }
        }, [_vm._v("Social Links")]), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Facebook",
            "vid": "facebook"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Facebook"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "url",
                  "placeholder": "Facebook"
                },
                model: {
                  value: _vm.form.social_media_links.facebook,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.social_media_links, "facebook", $$v);
                  },
                  expression: "form.social_media_links.facebook"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Twitter",
            "vid": "twitter"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Twitter"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "url",
                  "placeholder": "Twitter"
                },
                model: {
                  value: _vm.form.social_media_links.twitter,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.social_media_links, "twitter", $$v);
                  },
                  expression: "form.social_media_links.twitter"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Linkedin",
            "vid": "linkedin"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Linkedin"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "url",
                  "placeholder": "Linkedin"
                },
                model: {
                  value: _vm.form.social_media_links.linkedin,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form.social_media_links, "linkedin", $$v);
                  },
                  expression: "form.social_media_links.linkedin"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-card-text', {
          staticClass: "mb-0"
        }, [_vm._v(" Status ")]), _c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }